<template>
  <div class="project-template embed page-wrapper">
    <div class="embed-header" v-if="isSpaceRoute">
      <router-link tag="a" :to="{name: 'SpecificProjectEmbed', params: {projectId: activeProject}}" class="back-link">
        <AtomIcon icon="angle-left" :size="32" color="#000" />
      </router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>

import AtomIcon from "../components/atoms/common/AtomIcon";
export default {
  name: "ProjectEmbedTemplate",
  components: {AtomIcon},
  computed: {
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    isSpaceRoute() {
      return this.$route.name === 'SpecificSpaceEmbed';
    }
  }
}
</script>

<style lang="scss">
.project-template {
  display: block;
  &.embed {
    .project {
      padding: 0 !important;
    }
    .embed-header {
      position: absolute;
      top: 0.625rem;
      left: 0.625rem;
      z-index: 9;
      .back-link {
        display: block;
        position: relative;
        width: 52px;
        height: 52px;
        text-align: center;
        background: $white;
        color: $black;
        font-size: 23px;
        transition: all 0.2s ease-in-out 0s;
        .icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          transition: all 0.2s ease-in-out 0s;
        }
        &:hover {
          transform: scale(1.04);
          .icon {
            margin-left: -3px;
          }
        }
      }
    }
    .organism-building {
      #canvas-wrapper {
        width: 100vw !important;
      }
    }
  }
}
</style>